export const Footer = () => {
  return (
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; {new Date().getFullYear()} Artsko
          </p>
        </div>
      </div>
  )
}

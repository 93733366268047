import api from '../api/api';
import { useState, useEffect, useMemo } from "react";
import LoadingIcons from 'react-loading-icons'
import { Footer } from '../components/footer'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const themes = {
  'dark': {
    caption:{
      fontSize:16,
      color:'#cccccc'
    },
    title:{
      fontSize:20,
      fontWeight:'bold',
      color:'#cccccc'
    },
    textLighter:{
      color:'#aaaaaa'
    },
    backgroundColor:'#111111',
    accentColor:'#cc5151'
  },
  'light': {
    caption:{
      fontSize:16,
      color:'#999999'
    },
    title:{
      fontSize:20,
      fontWeight:'bold',
      color:'#999999'
    },
    textLighter:{
      color:'#aaaaaa'
    },
    backgroundColor:'#ffffff',
    accentColor:'#cc5151'
  }
}

function Commissions() {


    const [commission, setCommission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    

      const theme = useMemo(()=>{
        return themes['light'];
      },[])

    useEffect(() => {
      const f = async()=>{
        const commissionId = new URLSearchParams(window.location.search).get("id");
        if(!commissionId){
          return;
        }
        const response = await api.get(
          `/Commissions?$select=Name,Description&$filter=Id eq ${commissionId}&$expand=CommissionImages($orderBy=IsMain desc,Id asc;$filter=not NSFW;$select=Height,Width,Url,IsMain),Options($select=CurrentPrice,Description,Name,DaysToDeliver,Size;$filter=not IsArchived),Artist($select=Username,ProfileUrl),Category($select=Name)`,
        );
        const commission = response?.data?.value?.[0];
        setCommission(commission);
        setIsLoading(false);
      };
      f();
    }, []);

    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

   return (
     <div style={{display:'flex',flexDirection:'column'}}>
      <div style={{marginTop:80, display:'flex',flexDirection:'column', alignSelf:'center',maxWidth:800}}>
        {  
          isLoading ? 
            <LoadingIcons.Oval style={{alignSelf:'center'}} stroke={theme.accentColor} />
          :
          !commission
          ? <div style={{minHeight:100, display:'flex',alignItems:'center',justifyContent:'center'}}>
            Sorry We couldn't find that commission
          </div>:
          (<div style={{display:'flex',flexDirection:'column'}}>
            
            <div
          style={
            {
              width: '100%',
              display:'flex',
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor:theme.backgroundColor
            }
          }
        >
          <img
            alt="artist's profile"
            style={{
              width: 50,
              height: 50,
              borderRadius: 25,
              overflow: 'hidden',
              borderWidth: 1,
              margin: 8,
            }}
            src={
              commission?.Artist?.ProfileUrl
                ??  'img/portfolio/01-large.jpg'
            }
          />
          <div style={{ display:'flex',flexDirection: 'column', marginRight: 8,backgroundColor:theme.backgroundColor }}>
            <div style={{ fontWeight: 'bold',...theme.caption }}>{commission?.Artist?.Username}</div>
            <div style={{ display:'flex',flexDirection: 'row' }}>
              <div style={{...theme.caption}}>{commission?.Name}</div>
              <div style={{...theme.caption,...theme.textLighter}}>
                {commission?.Category?.Name ? ` • ${commission?.Category?.Name}` : ''}
              </div>
            </div>
          </div>
        </div>
        <Carousel
          autoPlay
          showThumbs={false}
        >
        {
          (commission?.CommissionImages ?? []).map(image => 
              <img style={{objectFit:'contain',maxWidth:image.Width}} width='100%'  alt={commission?.Name} src={image.Url}/>
          )        
        }        
        </Carousel>

        {commission?.Description ? (
          <div style={{display:'flex',justifyContent:'center',padding:16}}>
              <div style={{ padding: 8,...theme.caption }}>{commission.Description}</div>
          </div>
        ) : null}
        {commission?.Options?.length ? (
          <div
            style={{
              display:'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flexWrap:'wrap',
              margin: 8,
              justifyContent: 'center',
            }}
          >
            {
              commission.Options.map(o => (
                <div style={{display:'flex',flexDirection:'column',margin:16,padding:16,alignItems:'center',borderWidth:1,borderStyle:'solid',borderColor:theme.caption, borderRadius:10}}>
                  <div style={{margin:8,...theme.title}}>{currencyFormatter.format(o.CurrentPrice)}</div>
                  <div style={{margin:8,...theme.title}}>{o.Name}</div>
                  {o.Description ? <div style={{margin:8,...theme.caption}}>{o.Description}</div>:null}
                  {o.Size ? <div style={{margin:8,...theme.caption}}>{o.Size}</div>:null}
                  {o.DaysToDeliver ? <div style={{margin:8,...theme.caption}}>{o.DaysToDeliver} Delivery Days</div> : null}
                </div>
              ))
            }
          </div>
        ) : null}

          <div style={{display:'flex',padding:16, justifyContent:'center', ...theme.title}}>To purchase, download the Artsko app:</div>
          <div style={{display:'flex', justifyContent:'center'}}>
              <a href="https://apps.apple.com/us/app/artsko/id1582398116" alt="ios app store" >
                <img style={{margin:8}} alt="ios app store"  src="/img/ios_app_store.svg"/>
              </a>
              <a href="https://play.google.com/store/apps/details?id=art.artsko.artsko" alt="android app store">
                <img style={{margin:8}} height="58" alt="android app store"  src="/img/android_app_store.png"/>
              </a>
            </div>

        </div>)}
        
        </div>
        <Footer/>
      </div>
    );
  }

  export default Commissions;
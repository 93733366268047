import api from '../api/api';
import { useState, useEffect, useMemo } from "react";
import LoadingIcons from 'react-loading-icons'
import { Footer } from '../components/footer'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const themes = {
  'dark': {
    caption:{
      fontSize:16,
      color:'#cccccc'
    },
    title:{
      fontSize:20,
      fontWeight:'bold',
      color:'#cccccc'
    },
    textLighter:{
      color:'#aaaaaa'
    },
    backgroundColor:'#111111',
    accentColor:'#cc5151'
  },
  'light': {
    caption:{
      fontSize:16,
      color:'#999999'
    },
    title:{
      fontSize:20,
      fontWeight:'bold',
      color:'#999999'
    },
    textLighter:{
      color:'#aaaaaa'
    },
    backgroundColor:'#ffffff',
    accentColor:'#cc5151'
  }
}

function Purchases() {
    const [purchase, setPurchase] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    

      const theme = useMemo(()=>{
        return themes['light'];
      },[])

    useEffect(() => {
      const f = async()=>{
        const purchaseId = new URLSearchParams(window.location.search).get("id");
        if(!purchaseId){
          return;
        }
        const response = await api.get(
          `/Purchases?$filter=Id eq ${purchaseId}&$expand=Collector($select=Username,ProfileUrl),CommissionOption($expand=Commission($select=Name,Description,IsPublished,IsArchived;$expand=CommissionImages($orderBy=IsMain desc,Id asc;$filter=not NSFW;$select=Height,Width,Url,IsMain),Options($select=CurrentPrice,Description,Name,DaysToDeliver;$filter=not IsArchived),Artist($select=Username,ProfileUrl),Category($select=Name)))`,
        );
        const purchase = response?.data?.value?.[0];
        setPurchase(purchase);
        setIsLoading(false);
      };
      f();
    }, []);

   const image = purchase?.Url ? purchase : purchase?.CommissionOption?.Commission?.CommissionImages?.find(o => o.IsMain) ?? purchase?.CommissionImages[0];

   return (
     <div style={{display:'flex',flexDirection:'column'}}>
      <div style={{marginTop:80, display:'flex',flexDirection:'column', alignSelf:'center',maxWidth:800}}>
        {  
          isLoading ? 
            <LoadingIcons.Oval style={{alignSelf:'center'}} stroke={theme.accentColor} />
          :
          !purchase
          ? <div style={{minHeight:100, display:'flex',alignItems:'center',justifyContent:'center'}}>
            Sorry We couldn't find that gallery item
          </div>:
          (<div style={{display:'flex',flexDirection:'column'}}>
            
            <div
          style={
            {
              width: '100%',
              display:'flex',
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor:theme.backgroundColor
            }
          }
        >
          <img
            alt="collector's profile"
            style={{
              width: 50,
              height: 50,
              borderRadius: 25,
              overflow: 'hidden',
              borderWidth: 1,
              margin: 8,
            }}
            src={
              purchase?.Collector?.ProfileUrl
                ??  'img/portfolio/01-large.jpg'
            }
          />
          <div style={{ display:'flex',flexDirection: 'column', marginRight: 8,backgroundColor:theme.backgroundColor }}>
            <div style={{ fontWeight: 'bold',...theme.caption }}>{purchase?.Collector?.Username}</div>
            <div style={{ display:'flex',flexDirection: 'row' }}>
              {
                purchase?.CommissionOption?.Commission?.IsPublished && !purchase?.CommissionOption?.Commission?.IsArchived ? 
                  <a href={`commissions?id=${purchase?.CommissionOption?.CommissionId}`} style={{...theme.caption,...{textDecoration:'underline'}}}>{purchase?.CommissionOption?.Commission?.Name}</a>:
                  <div style={{...theme.caption}}>{purchase?.CommissionOption?.Commission?.Name}</div>
              }
              <div style={{...theme.caption,...theme.textLighter}}>
                {purchase?.CommissionOption?.Commission?.Category?.Name ? ` • ${purchase?.CommissionOption?.Commission?.Category?.Name}` : ''}
                {` • by ${purchase?.CommissionOption?.Commission?.Artist?.Username}`}
              </div>
            </div>
          </div>
        </div>
          <img style={{objectFit:'contain',maxWidth:image?.Width}} width='100%'  alt={purchase?.CommissionOption?.Commission?.Name} src={image?.Url}/>
       
          <div style={{display:'flex',padding:16, justifyContent:'center', ...theme.title}}>To see the rest of my art gallery, download the Artsko app:</div>
          <div style={{display:'flex', justifyContent:'center'}}>
          <a href="https://apps.apple.com/us/app/artsko/id1582398116" alt="ios app store" >
                  <img style={{margin:8}} alt="ios app store"  src="/img/ios_app_store.svg"/>
                </a>
                <a href="https://play.google.com/store/apps/details?id=art.artsko.artsko" alt="android app store">
                  <img style={{margin:8}} height="58" alt="android app store"  src="/img/android_app_store.png"/>
                </a>
            </div>

        </div>)}
        
        </div>
        <Footer/>
      </div>
    );
  }

  export default Purchases;